import { Card, Chip, Select, SelectItem } from '@nextui-org/react'
import {
   IconBrandWhatsapp,
   IconCheck,
   IconChevronDown,
   IconCircleCheck,
   IconDots,
   IconInfoCircle,
   IconMail,
   IconPhone,
   IconWorld
} from '@tabler/icons-react'
import { Avatar, Drawer, Empty, Form, List, Modal, Select as SelectBox, notification } from 'antd'
import { CUSTOMER_MANAGEMENT_LIST, CUSTOMER_MANAGEMENT_STORE } from 'api/customer-management'
import { USER_SHOW, USER_STORE } from 'api/user'
import { USER_STOCK_STORE } from 'api/userStock'
import axios from 'axios'
import { routes } from 'config/routes'
import { setCart } from 'features/user/basket'
import { setContactDetail, setContactId, setMail, setMailId, setProduct } from 'features/user/offer'
import { MoneyFormat } from 'hooks/currency'
import useFetch from 'hooks/use-fetch'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Alert, Badge, Button, Switch, Textarea, Title, Input, AdvancedRadio, Text, RadioGroup } from 'rizzui'
import SimpleBar from 'simplebar-react'
import { RootState } from 'store'
import _, { set } from 'lodash'
import { DotLoader } from 'react-spinners'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { send } from 'process'
type Props = {
   data: any
   id: any
   users?: any
}
function Mail(props: Props) {
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const carts = useSelector((state: RootState) => state.basket.carts)
   const user = JSON.parse(localStorage.getItem('user') as any)
   const { data, id } = props
   const message = data.find((item: any) => item.id == id)

   const [usersQuery, { data: usersData, loading: usersLoading }] = useFetch<any>()
   const [assignUserMutation, { data: assignUserData, loading: assignUserLoading }] = useFetch<any>()
   const [mailAssignLoading, setMailAssignLoading] = React.useState(false)
   const [checkGraphTokenQuery, { data: checkGraphTokenData, loading: checkGraphTokenLoading }] = useFetch<any>()
   const [getGraphTokenDeviceQuery, { data: getGraphTokenDeviceData, loading: getGraphTokenDeviceLoading }] =
      useFetch<any>()
   const [checkDeviceTokenMutation, { data: checkDeviceTokenData, loading: checkDeviceTokenLoading }] = useFetch<any>()
   const [updateUserGraphTokenMutation, { data: updateUserGraphTokenData, loading: updateUserGraphTokenLoading }] =
      useFetch<any>()
   const [getMailQuery, { data: getMailData, loading: getMailLoading }] = useFetch<any>()
   const [deviceTokenInfoModal, setDeviceTokenInfoModal] = React.useState(false)
   const [access_token, setAccess_token] = React.useState('')
   const [mailDetailOpen, setMailDetailOpen] = React.useState(false)
   const [setMailDetail, setMailDetailData] = React.useState<any>(null)

   const [cartDrawer, setCartDrawer] = React.useState(false)
   const [open, setOpen] = React.useState(false)
   const [requestForm] = Form.useForm()
   const [contactForm] = Form.useForm()
   const [contactModal, setContactModal] = React.useState(false)
   const [contactsQuery, { data: contactsData, loading: contactsLoading, error: contactsError }] = useFetch<any>()
   const [offerRequestMutation, { data: offerRequestData, loading: offerRequestLoading, error: offerRequestError }] =
      useFetch<any>()
   const [contactMutation, { data: contactData, loading: contactLoading, error: contactError }] = useFetch<any>()
   const [contact_id, setContact_id] = React.useState<any>(null)
   const [sendWithProduct, setSendWithProduct] = React.useState(false)
   const [checkSenderContactMail, { data: checkSenderContactMailData, loading: checkSenderContactMailLoading }] =
      useFetch<any>()
   const [hasContact, setHasContact] = React.useState(false)

   const [sendMail, { data: sendMailData, loading: sendMailLoading }] = useFetch<any>()

   const [sendMailOpen, setSendMailOpen] = React.useState(false)

   const [selectTypeModal, setSelectTypeModal] = React.useState(false)
   const [selectedType, setSelectedType] = React.useState<any>(null)

   const [sendMailForm] = Form.useForm()

   const [welcomeMailValue, setWelcomeMailValue] = React.useState<any>(`
      <p>Selamlar,</p>
    
    <p>Şirketimizin tanıtım dosyamızı ekte bulabilirsiniz.</p>
    
    <p>
        Her türlü promosyon ürünü, teknolojik – tekstil ürün, markalı ürün tedariğini Bayer, Borusan, Mercedes, Volvo, Reckitt, TEB, 
        Novartis, Siemens, Nestle, Eczacıbaşı, Loreal, Henkel ve daha birçok kurumsal firmaya 
        <strong>34 yılı aşkındır temin ediyoruz.</strong>
    </p>
    
    <ul>
        <li><em>Unilever, Booking.com ve Sandoz firmalarının tek tedarikçisiyiz.</em></li>
        <li><strong style="background-color: lime;">Stanley, Moleskine, Hugo Boss, Aladdin, Mugo, Net Zero Cup ve Pigra markalarının Türkiye distribütörüyüz.</strong></li>
        <li><em>Algida, Oyak, Socar, Opel, Citroen, Kerry, Peugeot ve Mercedes gibi firmalara webshop hizmeti sunuyoruz.</em></li>
    </ul>

    <p>
        Gimel hakkında detaylar için yandaki linke bağlanmanızı rica ederiz: 
        <a href="https://gimel.com.tr" target="_blank">Hakkımızda (gimel.com.tr)</a>
    </p>
    
    <p>
        Gimel’in hizmetleri için linke bağlanmanızı rica ederiz: 
        <a href="https://www.gimel.com.tr/hizmetlerimiz" target="_blank">https://www.gimel.com.tr/hizmetlerimiz</a>
    </p>
    
    <p>Haberlerinizi ve taleplerinizi heyecanla bekliyoruz.</p>
    
    <p>Sevgiler,</p>
      `)
   const [subject, setSubject] = React.useState<any>('Bizi Daha Yakından Tanımak İster Misiniz ? / Gimel')

   const [isSendWelcomeMail, setIsSendWelcomeMail] = React.useState(false)
   const [isMobile, setIsMobile] = React.useState(false)

   useEffect(() => {
      //@ts-ignore
      usersQuery(USER_SHOW, {
         endPoint: '/users-with-offer-current-status'
      })
   }, [])

   const checkMailContact = (item: any) => {
      //@ts-ignore
      checkSenderContactMail(USER_STORE, {
         endPoint: '/check-mail-contact',
         body: {
            email: item.sender?.emailAddress?.address
         }
      })
   }

   const sendMailDetail = (values: any) => {
      //@ts-ignore
      sendMail(USER_STORE, {
         endPoint: '/send-mail',
         body: {
            ...values,
            mail: setMailDetail?.from?.emailAddress?.address
         }
      })
   }

   const handleCreateRequest = (values: any) => {
      console.log('values', values)
      //@ts-ignore8
      offerRequestMutation(USER_STORE, {
         endPoint: '/offer-requests-create',

         body: {
            ...values,
            carts: values.send_with_product ? carts : [],
            mailContent: setMailDetail
         }
      })
   }

   useEffect(() => {
      if (offerRequestData) {
         notification.success({
            message: 'Başarılı',
            description: 'Talep oluşturuldu.'
         })
         setOpen(false)
      }
   }, [offerRequestData])

   useEffect(() => {
      if (contactData) {
         // message.success('Müşteri başarıyla eklendi!')
         notification.success({
            message: 'Başarılı',
            description: 'Müşteri başarıyla eklendi.'
         })

         dispatch(setContactId(contactData.contact_id))
         setContactModal(false)
         setContact_id(contactData.contact_id)
         //@ts-ignore
         contactsQuery(CUSTOMER_MANAGEMENT_LIST, {
            endPoint: '/customer-with-contacts'
         })
      }
   }, [contactData])

   useEffect(() => {
      //@ts-ignore
      contactsQuery(CUSTOMER_MANAGEMENT_LIST, {
         endPoint: '/customer-with-contacts'
      })
   }, [])

   const handleSubmitContact = (values: any) => {
      console.log('values', values)
      //@ts-ignore
      contactMutation(CUSTOMER_MANAGEMENT_STORE, {
         endPoint: 'customers/addContactOffer',
         body: {
            ...values,
            sendWelcomeMail: isSendWelcomeMail,
            welcomeMail: welcomeMailValue,
            subject: subject
         }
      })
   }

   const handleSaveMailList = () => {
      //@ts-ignore
      contactMutation(CUSTOMER_MANAGEMENT_STORE, {
         endPoint: 'customers/saveMailList',
         body: {
            email: setMailDetail?.sender?.emailAddress?.address
         }
      })
   }

   useEffect(() => {
      //@ts-ignore
      checkGraphTokenQuery(USER_SHOW, {
         endPoint: '/check-graph-token'
      })
   }, [])

   useEffect(() => {
      if (checkGraphTokenData?.response === false) {
         //@ts-ignore
         getGraphTokenDeviceQuery(USER_SHOW, {
            endPoint: '/get-graph-device-code'
         })
      } else if (checkGraphTokenData?.status == false) {
         //@ts-ignore
         getGraphTokenDeviceQuery(USER_SHOW, {
            endPoint: '/get-graph-device-code'
         })
      } else {
         //@ts-ignore
         getMailQuery(USER_SHOW, {
            endPoint: '/get-mails'
         })
      }
   }, [checkGraphTokenData])

   useEffect(() => {
      if (getGraphTokenDeviceData) {
         setDeviceTokenInfoModal(true)
      }
   }, [getGraphTokenDeviceData])

   const [modal, setModal] = React.useState(false)
   const [form] = Form.useForm()
   const onFinish = (values: any) => {
      //@ts-ignore
      assignUserMutation(USER_STOCK_STORE, {
         endPoint: `/mails/assignUser`,
         method: 'POST',
         body: {
            user_id: values.user,
            description: values.description,
            mail: setMailDetail
         }
      })
   }

   useEffect(() => {
      if (assignUserData) {
         notification.success({
            message: 'Atama Başarılı',
            description: 'Mail başarıyla atandı'
         })
         setModal(false)
      }
   }, [assignUserData])

   const handleCreateOffer = async () => {
      if (carts.length > 0) {
         dispatch(setMailId(id))
         Modal.confirm({
            title: 'Sepetinizde ürünler bulunmaktadır.',
            content: 'Ürünlerinizi teklife eklemek istiyor musunuz?',
            onOk() {
               dispatch(setMail(true))
               dispatch(setProduct(carts))
               navigate(routes.offerManagement.add + '?mail_id=' + id)
            },
            onCancel() {
               dispatch(setMail(true))
               navigate(routes.offerManagement.add + '?mail_id=' + id)
            }
         })
      } else {
         dispatch(setMailId(id))
         dispatch(setMail(true))
         dispatch(setProduct([]))
         navigate(routes.offerManagement.add + '?mail_id=' + id)
      }
   }

   const checkDeviceToken = () => {
      //@ts-ignore
      checkDeviceTokenMutation(USER_STORE, {
         endPoint: '/check-device-token',
         body: {
            device_code: getGraphTokenDeviceData?.device_code
         }
      })
   }

   useEffect(() => {
      if (checkDeviceTokenData) {
         if (checkDeviceTokenData?.error) {
            if (checkDeviceTokenData?.error == 'authorization_pending') {
               setTimeout(() => {
                  checkDeviceToken()
               }, 5000)
            }
         } else {
            // setAccess_token(checkDeviceTokenData?.access_token)
            // localStorage.setItem('access_token', checkDeviceTokenData?.access_token)

            //@ts-ignore

            updateUserGraphTokenMutation(USER_STORE, {
               endPoint: '/update-user-graph-token',
               body: {
                  access_token: checkDeviceTokenData?.access_token,
                  device_code: getGraphTokenDeviceData?.device_code,
                  refresh_token: checkDeviceTokenData?.refresh_token
               }
            })

            // @ts-ignore

            getMailQuery(USER_SHOW, {
               endPoint: '/get-mails'
            })
         }
      }
   }, [checkDeviceTokenData])

   useEffect(() => {
      if (updateUserGraphTokenData) {
         setDeviceTokenInfoModal(false)
         Modal.success({
            title: 'Başarılı',
            content: 'Mail oturumunuz başarıyla oluşturuldu.',
            onOk() {},
            closeIcon: true,
            closable: true,
            okText: 'Tamam'
         })
      }
   }, [updateUserGraphTokenData])

   useEffect(() => {
      if (checkSenderContactMailData) {
         console.log('checkSenderContactMailData', checkSenderContactMailData)
         if (checkSenderContactMailData?.status == 'success') {
            setHasContact(true)
         } else {
            setHasContact(false)
         }
         setMailDetailOpen(true)
      }
   }, [checkSenderContactMailData])

   useEffect(() => {
      if (sendMailData) {
         notification.success({
            message: 'Başarılı',
            description: 'Mail başarıyla gönderildi.'
         })
         setSendMailOpen(false)
      }
   }, [sendMailData])

   useEffect(() => {
      if (window.innerWidth < 768) {
         setIsMobile(true)
      }
   }, [window.innerWidth])

   const RenderDesktop = () => {
      return (
         <table className="min-w-full bg-white border">
            <thead>
               <tr>
                  <th className="py-3 px-4 border-b">Yıldız</th>
                  <th className="py-3 px-4 border-b">Gönderen</th>
                  <th className="py-3 px-4 border-b">Konu</th>
                  <th className="py-3 px-4 border-b">Tarih</th>
                  <th className="py-3 px-4 border-b"></th>
               </tr>
            </thead>
            <tbody>
               {/* {emails.map(email => (
                  <tr key={email.id} className="hover:bg-gray-100">
                     <td className="py-3 px-4 border-b">
                        <input type="checkbox" />
                     </td>
                     <td className="py-3 px-4 border-b">
                        <div className="flex items-center">
                           <img className="w-10 h-10 rounded-full mr-2" src={email.avatar} alt={email.sender} />
                           <span>{email.sender}</span>
                        </div>
                     </td>
                     <td className="py-3 px-4 border-b">{email.subject}</td>
                     <td className="py-3 px-4 border-b">{email.date}</td>
                  </tr>
               ))} */}
               {getMailData?.value?.map((item: any, index: number) => {
                  const keywords = [
                     'fiyat',
                     'teklif',
                     'ödeme',
                     'satın alma',
                     'indirim',
                     'sipariş',
                     'ürünler',
                     'ürün',
                     'hizmet',
                     'müşteri',
                     'talep',
                     'anlaşma',
                     'kontrat',
                     'işbirliği',
                     'proje',
                     'yatırım',
                     'ihale',
                     'bütçe',
                     'görüşme',
                     'müşteri',
                     'satış',
                     'fiyat teklifi',
                     'ödeme şartları',
                     'satın alma süreci',
                     'indirim oranı',
                     'sipariş detayları',
                     'ürün bilgisi',
                     'hizmet bedeli',
                     'müşteri talepleri',
                     'talep edilen fiyat',
                     'anlaşma şartları',
                     'kontrat maddeleri',
                     'işbirliği fırsatları',
                     'proje teklifi',
                     'yatırım fırsatları',
                     'ihale süreci',
                     'bütçe planı',
                     'görüşme takvimi',
                     'müşteri memnuniyeti',
                     'satış hedefleri',
                     'teklif sunumu',
                     'finansal detaylar',
                     'teslimat süresi',
                     'garanti koşulları',
                     'ödeme planı',
                     'ürün incelemesi',
                     'müşteri geri bildirimi',
                     'sipariş onayı',
                     'teslimat koşulları',
                     'kargo bilgileri',
                     'satış raporu'
                  ]

                  const lowerCaseContent = item.body.content.toLowerCase()
                  const foundKeywords = keywords.filter(keyword => lowerCaseContent.includes(keyword))
                  return (
                     <tr
                        key={item.id}
                        className="hover:bg-gray-100"
                        style={{
                           background: item.isRead ? 'white' : '#f0f0f0'
                        }}
                     >
                        <td className="py-3 px-4 border-b">
                           <input type="checkbox" />
                        </td>
                        <td className="py-3 px-4 border-b">
                           <div className="flex items-center">
                              {/* <span>{item.sender?.emailAddress?.name}</span> */}
                              <span className={item.isRead ? 'font-medium' : 'font-semibold'}>
                                 {item.sender?.emailAddress?.name}
                              </span>
                           </div>
                        </td>
                        <td className="py-3 px-4 border-b">
                           <span className={item.isRead ? 'font-medium' : 'font-semibold'}>{item.subject}</span>
                        </td>
                        <td className="py-3 px-4 border-b">
                           {moment(item.createdDateTime).format('DD.MM.YYYY HH:mm')}
                        </td>
                        <td className="py-3 px-4 border-b flex items-center gap-2">
                           <span className="flex gap-2">
                              <Button
                                 variant="outline"
                                 color="primary"
                                 isLoading={
                                    checkSenderContactMailLoading &&
                                    setMailDetail?.sender?.emailAddress?.address == item.sender?.emailAddress?.address
                                 }
                                 onClick={() => {
                                    setMailDetailData(item)
                                    checkMailContact(item)
                                 }}
                              >
                                 Mail İçeriğini Gör
                              </Button>

                              {foundKeywords.length > 0 && (
                                 <span className="text-red-500 flex items-center gap-2 animation-pulse">
                                    <IconInfoCircle size={24} />
                                    Teklif Potansiyeli
                                 </span>
                              )}
                           </span>
                        </td>
                     </tr>
                  )
               })}
            </tbody>
         </table>
      )
   }

   const RenderMobile = () => {
      return (
         <div className="space-y-4">
            {getMailData?.value?.map((item: any, index: number) => {
               let keys: any = [
                  'fiyat',
                  'teklif',
                  'ödeme',
                  'satın alma',
                  'indirim',
                  'sipariş',
                  'ürünler',
                  'ürün',
                  'hizmet',
                  'müşteri',
                  'talep',
                  'anlaşma',
                  'kontrat',
                  'işbirliği',
                  'proje',
                  'yatırım',
                  'ihale',
                  'bütçe',
                  'görüşme',
                  'müşteri',
                  'satış',
                  'fiyat teklifi',
                  'ödeme şartları',
                  'satın alma süreci',
                  'indirim oranı',
                  'sipariş detayları',
                  'ürün bilgisi',
                  'hizmet bedeli',
                  'müşteri talepleri',
                  'talep edilen fiyat',
                  'anlaşma şartları',
                  'kontrat maddeleri',
                  'işbirliği fırsatları',
                  'proje teklifi',
                  'yatırım fırsatları',
                  'ihale süreci',
                  'bütçe planı',
                  'görüşme takvimi',
                  'müşteri memnuniyeti',
                  'satış hedefleri',
                  'teklif sunumu',
                  'finansal detaylar',
                  'teslimat süresi',
                  'garanti koşulları',
                  'ödeme planı',
                  'ürün incelemesi',
                  'müşteri geri bildirimi',
                  'sipariş onayı',
                  'teslimat koşulları',
                  'kargo bilgileri',
                  'satış raporu'
               ]

               const lowerCaseContent = item.body.content.toLowerCase()
               const foundKeywords = keys.filter((keyword: any) => lowerCaseContent.includes(keyword))

               return (
                  <div
                     key={item.id}
                     className={`p-4 border rounded-lg shadow-sm ${
                        item.isRead ? 'bg-white' : 'bg-gray-100'
                     } hover:bg-gray-200`}
                  >
                     <div className="flex items-center justify-between mb-2">
                        <div className="flex items-center">
                           <input type="checkbox" className="mr-2" />
                           <div className={item.isRead ? 'font-medium' : 'font-semibold'}>
                              {item.sender?.emailAddress?.name}
                           </div>
                        </div>
                        <div className="text-sm text-gray-500">
                           {moment(item.createdDateTime).format('DD.MM.YYYY HH:mm')}
                        </div>
                     </div>

                     <div className="mb-2">
                        <div className={item.isRead ? 'font-medium text-lg' : 'font-semibold text-lg'}>
                           {item.subject}
                        </div>
                     </div>

                     <div className="flex items-center justify-between">
                        <Button
                           variant="outline"
                           color="primary"
                           isLoading={
                              checkSenderContactMailLoading &&
                              setMailDetail?.sender?.emailAddress?.address == item.sender?.emailAddress?.address
                           }
                           onClick={() => {
                              setMailDetailData(item)
                              checkMailContact(item)
                           }}
                        >
                           Mail İçeriğini Gör
                        </Button>

                        {foundKeywords.length > 0 && (
                           <span className="text-red-500 flex items-center gap-2 animate-pulse">
                              <IconInfoCircle size={24} />
                              Teklif Potansiyeli
                           </span>
                        )}
                     </div>
                  </div>
               )
            })}
         </div>
      )
   }

   return (
      <div>
         <Modal title="Mail Gönder" open={sendMailOpen} footer={null} onCancel={() => setSendMailOpen(false)}>
            <Form
               initialValues={{
                  subject: message?.subject
               }}
               form={sendMailForm}
               layout="vertical"
               onFinish={sendMailDetail}
            >
               <Form.Item
                  label="Mail Konusu"
                  name="subject"
                  rules={[{ required: true, message: 'Lütfen mail konusu giriniz!' }]}
               >
                  <Input placeholder="Mail konusu giriniz" />
               </Form.Item>
               <Form.Item
                  label="Mail İçeriği"
                  name="content"
                  rules={[{ required: true, message: 'Lütfen mail içeriği giriniz!' }]}
               >
                  <Textarea placeholder="Mail içeriği giriniz" />
               </Form.Item>
               <Button isLoading={sendMailLoading} type="submit" color="primary">
                  Gönder
               </Button>
            </Form>
         </Modal>
         <Modal open={contactModal} footer={null} onCancel={() => setContactModal(false)} title="Yeni Müşteri Ekle">
            <Form form={contactForm} layout="vertical" onFinish={handleSubmitContact}>
               <Form.Item
                  label="Firma Adı"
                  name="company_name"
                  rules={[{ required: true, message: 'Lütfen firma adı giriniz!' }]}
               >
                  <Input placeholder="Firma adı giriniz" />
               </Form.Item>
               <Form.Item
                  label="İlgili müşteri adı"
                  name="contact_name"
                  rules={[{ required: true, message: 'Lütfen ilgili müşteri adı giriniz!' }]}
               >
                  <Input placeholder="İlgili müşteri adı giriniz" />
               </Form.Item>
               <Form.Item
                  label="Telefon"
                  name="phone"
                  rules={[{ required: false, message: 'Lütfen telefon numarası giriniz!' }]}
               >
                  <Input placeholder="Telefon numarası giriniz" />
               </Form.Item>
               <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Lütfen email adresi giriniz!' }]}
               >
                  <Input placeholder="Email adresi giriniz" />
               </Form.Item>
               <Button isLoading={contactLoading} type="submit" color="primary">
                  Kaydet
               </Button>
            </Form>
         </Modal>
         <Modal
            width={1000}
            open={open}
            onCancel={() => {
               setOpen(false)
            }}
            footer={null}
         >
            <Title as="h5">Hızlı Talep Oluştur</Title>
            <Form
               initialValues={{
                  offer_source: 'email'
               }}
               layout="vertical"
               name="basic"
               form={form}
               onFinish={handleCreateRequest}
            >
               <Alert color="info">
                  <p>
                     Kullanıcıların solundaki numaralar elindeki var olan işlem bekleyen taleplerin sayısını
                     göstermektedir.
                  </p>
               </Alert>
               <Form.Item
                  label="Müşteri seçiniz"
                  name="contact_id"
                  rules={[{ required: true, message: 'Lütfen bir müşteri seçiniz!' }]}
               >
                  <SelectBox
                     onChange={(value: any) => {
                        const find = contactsData?.data?.find((item: any) =>
                           item.contacts.some((contact: any) => contact.id === value)
                        )
                        if (find) {
                           const contactDetail = find.contacts.find((contact: any) => contact.id === value)
                           dispatch(setContactDetail(contactDetail))
                        }
                     }}
                     options={contactsData?.data
                        .filter((item: any) => item.contacts.length > 0)
                        .map((item: any) => {
                           return {
                              label: item.company_name,
                              options: item.contacts.map((contact: any) => {
                                 return {
                                    label: contact.name,
                                    value: contact.id
                                 }
                              })
                           }
                        })}
                     filterOption={(input: any, option: any) =>
                        option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        option?.options?.some((item: any) => item.label.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                     }
                     showSearch
                     notFoundContent={
                        <div className="flex flex-col items-center justify-center">
                           <Empty description="Müşteri seçiniz bulunamadı" />
                           <Button color="primary" onClick={() => setContactModal(true)}>
                              Yeni Müşteri Ekle
                           </Button>
                        </div>
                     }
                     placeholder="müşteri seçiniz"
                     suffixIcon={<IconChevronDown />}
                  ></SelectBox>
               </Form.Item>
               <Form.Item
                  label="Atanacak Kullanıcı"
                  name="user_id"
                  rules={[{ required: true, message: 'Lütfen atanacak kullanıcıyı seçiniz!' }]}
               >
                  <Select size="sm" label="Kullanıcı Seç" placeholder="Kullanıcı">
                     {usersData &&
                        usersData?.result
                           ?.sort((a: any, b: any) => a.total - b.total)
                           .map((item: any) => (
                              <SelectItem
                                 startContent={<Badge color="primary">{item.total}</Badge>}
                                 key={item.id}
                                 value={item.id}
                              >
                                 {item?.name}
                              </SelectItem>
                           ))}
                  </Select>
               </Form.Item>

               <Form.Item name="offer_source" label="Talep Kaynağı">
                  <Select value={'email'} size="sm" label="Talep Kaynağı Seç" placeholder="Talep Kaynağı">
                     <SelectItem startContent={<IconWorld size={20} />} key={'web'} value="web">
                        Web
                     </SelectItem>
                     <SelectItem startContent={<IconMail size={20} />} key={'email'} value="email">
                        E-posta
                     </SelectItem>
                     <SelectItem startContent={<IconBrandWhatsapp size={20} />} key={'whatsapp'} value="whatsapp">
                        Whatsapp
                     </SelectItem>
                     <SelectItem startContent={<IconPhone size={20} />} key={'phone'} value="phone">
                        Telefon
                     </SelectItem>
                     <SelectItem startContent={<IconDots size={20} />} key={'other'} value="other">
                        Diğer
                     </SelectItem>
                  </Select>
               </Form.Item>
               <Form.Item name="note" label="Not">
                  <Textarea style={{ height: 100 }} />
               </Form.Item>
               <Form.Item name="send_with_product" valuePropName="checked">
                  <Switch
                     onChange={(e: any) => {
                        setSendWithProduct(e.target.checked)
                     }}
                     size="xl"
                     label="Oluşturduğum Sepetle Gönder"
                     labelClassName="font-medium"
                  />
               </Form.Item>
               {sendWithProduct && carts.length > 0 && (
                  <Card className="p-4">
                     <Title as="h5">Sepetteki Ürünler</Title>
                     <List
                        itemLayout="horizontal"
                        dataSource={carts}
                        renderItem={(item: any) => (
                           <List.Item>
                              <List.Item.Meta
                                 avatar={<Avatar src={item.thumb} />}
                                 title={item.name}
                                 description={item.model}
                              />

                              <div className="flex items-center gap-2">
                                 <Chip color="success">{MoneyFormat(item.price, 'TRY')}</Chip>
                                 <input
                                    className="w-20 p-1 border border-gray-300 rounded-md"
                                    type="number"
                                    onChange={e => {
                                       const currentProducts = _.cloneDeep(carts)
                                       const product = currentProducts.find(
                                          (product: any) => product.model === item.model
                                       )
                                       product.stock = e.target.value
                                       dispatch(setCart(currentProducts))
                                    }}
                                    value={item.stock}
                                 />
                              </div>
                           </List.Item>
                        )}
                     />
                  </Card>
               )}
               <Form.Item className="flex gap-5 items-center">
                  <Button isLoading={offerRequestLoading as boolean} type="submit" color="primary">
                     Talep Oluştur
                  </Button>
                  <Button
                     color="secondary"
                     className="ml-5"
                     onClick={() => {
                        setOpen(false)
                     }}
                  >
                     İptal
                  </Button>
               </Form.Item>
            </Form>
         </Modal>
         <Modal
            title="Yeni Mail Oturum Kaydı"
            open={deviceTokenInfoModal}
            footer={null}
            closable
            onCancel={() => setDeviceTokenInfoModal(false)}
         >
            {getGraphTokenDeviceData && (
               <div>
                  <p className="text-sm font-medium">
                     Cihazınızın güvenliğini sağlamak için aşağıdaki kodu kullanınız.
                  </p>
                  <p className="text-sm font-medium">Kod: {getGraphTokenDeviceData?.user_code}</p>
                  <p className="text-sm font-medium">
                     {' '}
                     <a href={getGraphTokenDeviceData?.verification_uri} className="text-blue" target="_blank">
                        {getGraphTokenDeviceData?.verification_uri}
                     </a>
                  </p>
                  <Button
                     isLoading={checkDeviceTokenLoading}
                     color="primary"
                     onClick={() => {
                        checkDeviceToken()
                     }}
                  >
                     Tamam
                  </Button>
               </div>
            )}
         </Modal>
         <Modal title="Atama Yap" open={modal} footer={null} closable onCancel={() => setModal(false)}>
            <Form form={form} layout="vertical" name="form_in_modal" onFinish={onFinish}>
               <Form.Item name="user" label="Kullanıcı" rules={[{ required: true, message: 'Kullanıcı seçiniz!' }]}>
                  <SelectBox placeholder="Seçim">
                     {props.users?.result?.map((user: any) => (
                        <SelectBox.Option key={user.id} value={user.id}>
                           {user.name}
                        </SelectBox.Option>
                     ))}
                  </SelectBox>
               </Form.Item>
               <Form.Item
                  name="description"
                  label="Açıklama"
                  rules={[{ required: false, message: 'Açıklama giriniz!' }]}
               >
                  <Textarea />
               </Form.Item>
               <div className="gap-2 flex">
                  <Button type="submit" color="primary" isLoading={assignUserLoading} onClick={form.submit}>
                     Atama Yap
                  </Button>
                  <Button color="danger" onClick={() => setModal(false)}>
                     İptal
                  </Button>
               </div>
            </Form>
         </Modal>
         {/* <table className="min-w-full bg-white border">
            <tbody>
               {getMailLoading && (
                  <tr>
                     <td colSpan={4} className="text-center">
                        Yükleniyor...
                     </td>
                  </tr>
               )}
               {getMailData?.value?.map((item: any, index: number) => (
                 
               ))}
            </tbody>
         </table> */}
         <Drawer
            placement="right"
            width={isMobile ? '100%' : window.innerWidth / 1.2}
            title={isMobile ? '' : 'Mail İçeriği'}
            open={mailDetailOpen}
            onClose={() => {
               setMailDetailOpen(false)
               setHasContact(false)
               setMailDetailData(null)
            }}
            extra={
               <div className="flex items-center gap-2">
                  <Button
                     size="DEFAULT"
                     variant="outline"
                     color="primary"
                     onClick={() => {
                        const user = JSON.parse(localStorage.getItem('user') as any)
                        if (user.role == 'admin') {
                           setOpen(true)
                        } else {
                           dispatch(setContactId(checkSenderContactMailData?.data?.id))
                           navigate(routes.offerManagement.add)
                        }
                     }}
                  >
                     {/* Hızlı talep oluştur */}
                     {JSON.parse(localStorage.getItem('user') as any).role == 'admin'
                        ? 'Hızlı talep oluştur'
                        : 'Teklif Oluştur'}
                  </Button>
                  <Button size="DEFAULT" variant="outline" color="primary" onClick={() => setSendMailOpen(true)}>
                     Maili Yanıtla
                  </Button>
               </div>
            }
         >
            <div className="my-2">
               {hasContact == false && (
                  <Alert color="info" className="flex items-center gap-10">
                     <span>Bu mail uzantısı mail listenizde yok, eklemek ister misiniz?</span>
                     <Button
                        size="sm"
                        color="primary"
                        className="ml-5"
                        onClick={() => {
                           // contactForm.setFieldValue('email', setMailDetail?.sender?.emailAddress?.address)
                           // contactForm.setFieldValue('phone', setMailDetail?.sender?.emailAddress?.address)
                           // setContactModal(true)
                           setSelectTypeModal(true)
                        }}
                     >
                        Müşteri Olarak Ekle
                     </Button>
                  </Alert>
               )}
            </div>

            {mailDetailOpen && (
               <div
                  dangerouslySetInnerHTML={{
                     __html: setMailDetail?.body.content
                  }}
               ></div>
            )}
         </Drawer>
         <Drawer
            open={selectTypeModal}
            title="Müşteri Tipini Seç"
            footer={null}
            onClose={() => setSelectTypeModal(false)}
            width={isMobile ? '100%' : window.innerWidth / 2.5}
         >
            <div className="bg-white p-2">
               <RadioGroup
                  value={selectedType}
                  setValue={setSelectedType}
                  className="grid grid-cols-1 sm:grid-cols-3 max-w-2xl  gap-4"
               >
                  <AdvancedRadio
                     key={'promosyon'}
                     name="payment"
                     value={'promosyon'}
                     className={`border-1 border-${
                        selectedType == 'promosyon' ? 'black' : 'gray-500'
                     } p-5 rounded-md cursor-pointer hover:border-black`}
                     inputClassName="border-1 border-black p-5 [&:checked~span_.icon]:block"
                  >
                     <span className="flex justify-between">
                        <Text as="b">{`Promosyon`}</Text>
                        <IconCircleCheck className="icon hidden h-5 w-5 text-primary" />
                     </span>
                  </AdvancedRadio>
                  <AdvancedRadio
                     key={'customer'}
                     name="payment"
                     value={'customer'}
                     className={`border-1 border-${
                        selectedType == 'customer' ? 'black' : 'gray-500'
                     } p-5 rounded-md cursor-pointer hover:border-black`}
                     inputClassName="[&:checked~span_.icon]:block"
                  >
                     <span className="flex justify-between">
                        <Text as="b">{`Müşteri`}</Text>
                        <IconCircleCheck className="icon hidden h-5 w-5 text-black" />
                     </span>
                  </AdvancedRadio>
               </RadioGroup>
               {selectedType == 'customer' && (
                  <div className="">
                     <Input label="Konu" onChange={e => setSubject(e.target.value)} value={subject} />
                     <ReactQuill
                        theme="snow"
                        className="mt-2"
                        value={welcomeMailValue}
                        onChange={setWelcomeMailValue}
                        placeholder="Müşteriye gönderilecek mail içeriğini buraya yazabilirsiniz."
                     />
                     <Switch
                        label="Hoşgeldin maili gönder"
                        size="xl"
                        checked={isSendWelcomeMail}
                        onChange={e => setIsSendWelcomeMail(e.target.checked)}
                     />
                     <Button
                        onClick={() => {
                           setSelectTypeModal(false)
                           contactForm.setFieldValue('email', setMailDetail?.sender?.emailAddress?.address)

                           setContactModal(true)
                        }}
                        color="primary"
                        className="mt-2"
                     >
                        Kaydet
                     </Button>
                  </div>
               )}

               {selectedType == 'promosyon' && (
                  <Button onClick={handleSaveMailList} color="primary" className="mt-2">
                     Mail listesine kaydet
                  </Button>
               )}
            </div>
         </Drawer>
         {isMobile ? <RenderMobile /> : <RenderDesktop />}
      </div>
   )
}

export default Mail
