import { Button } from '@nextui-org/react'
import { Card, Drawer, Empty, Form, List, Modal, Select, message } from 'antd'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Step2 from './Steps/Step2'
import useFetch from 'hooks/use-fetch'
import { IconChevronDown } from '@tabler/icons-react'
import { Input, Text } from 'rizzui';
import { CUSTOMER_MANAGEMENT_LIST, CUSTOMER_MANAGEMENT_STORE } from 'api/customer-management';
import { useDispatch, useSelector } from 'react-redux'
import { clearOffer, setContactDetail, setContactId, setCurrency, setOfferNumber, setTermTime, setUserId } from 'features/user/offer'
import { RootState } from 'store'
import Step3 from './Steps/Step3'
import { OFFER_MANAGEMENT_LIST, OFFER_MANAGEMENT_STORE, OFFER_MANAGEMENT_STORE_URL } from 'api/offer-management'
import _ from 'lodash'
import axios from 'axios';

function Create() {
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const offer = useSelector((state: RootState) => state.offer)
   console.log(offer)
   const [step1DrawerOpen, setStep1DrawerOpen] = useState(true)
   const [step2DrawerOpen, setStep2DrawerOpen] = useState(false)
   const [step3DrawerOpen, setStep3DrawerOpen] = useState(false)
   const [contactModal, setContactModal] = useState(false)
   const [contactsQuery, { data: contactsData, loading: contactsLoading, error: contactsError }] = useFetch<any>()
   const [offerCreateMutation, { data: offerCreateData, loading: offerCreateLoading, error: offerCreateError }] =
      useFetch<any>()
   const [usersQuery, { data: usersData, loading: usersLoading, error: usersError }] = useFetch<any>()
   const [
      getOfferNumberQuery,
      { data: getOfferNumberData, loading: getOfferNumberLoading, error: getOfferNumberError }
   ] = useFetch<any>()
   const [contactMutation, { data: contactData, loading: contactLoading, error: contactError }] = useFetch<any>()
   const [selectedCurrency, setSelectedCurrency] = useState('TRY')
   const [currencyModal, setCurrencyModal] = useState(false)
   const [currencyData, setCurrencyData] = useState<any>([])
   const [contact_id, setContact_id] = useState(0)

   const [groupedCurrency, setGroupedCurrency] = useState<any>([])

   const windowHeight = window.innerHeight

   const step2DrawerHeight = windowHeight - 0

   const step3DrawerHeight = step2DrawerHeight - 0

   const [form] = Form.useForm()
   const [contactForm] = Form.useForm()

   const handleSubmitContact = (values: any) => {
      //@ts-ignore
      contactMutation(CUSTOMER_MANAGEMENT_STORE, {
         endPoint: 'customers/addContactOffer',
         body: {
            ...values
         }
      })
   }

   const handleStep1 = (values: any) => {
      dispatch(setOfferNumber(values.offer_no))
      dispatch(setContactId(values.contact_id))
      dispatch(setTermTime(values.term_time))
      dispatch(setUserId(values.user_id))
      setStep1DrawerOpen(false)
      setStep2DrawerOpen(true)
   }

   const handleStep2 = (products: any) => {
      console.log(products)
      setStep2DrawerOpen(false)
      setStep3DrawerOpen(true)
   }

   const saveOffer = async () => {





      //@ts-ignore
      offerCreateMutation(OFFER_MANAGEMENT_STORE, {
         endPoint: OFFER_MANAGEMENT_STORE_URL,

         body: {
            ...offer,
            sendMail: offer.sendMail ?? false,
            cc: offer.cc ?? '',
            products: offer.products,
            selectedCurrency: selectedCurrency
         }
      })
   }

   const handleSaveOffer = () => {
      //@ts-ignore
      offerCreateMutation(OFFER_MANAGEMENT_STORE, {
         endPoint: OFFER_MANAGEMENT_STORE_URL,

         body: {
            ...offer,
            sendMail: offer.sendMail ?? false,
            cc: offer.cc ?? '',
            products: offer.products,
            selectedCurrency: selectedCurrency
         }
      })
   }

   useEffect(() => {
      if (offerCreateData) {
         message.success('Teklif başarıyla oluşturuldu!')
         dispatch(clearOffer())
         navigate(-1)
      }
   }, [offerCreateData])

   useEffect(() => {
      if (contactData) {
         message.success('Müşteri başarıyla eklendi!')

         setContactModal(false)
         setContact_id(contactData.contact_id)
         //@ts-ignore
         contactsQuery(CUSTOMER_MANAGEMENT_LIST, {
            endPoint: '/customer-with-contacts'
         })
      }
   }, [contactData])

   useEffect(() => {
      //@ts-ignore
      contactsQuery(CUSTOMER_MANAGEMENT_LIST, {
         endPoint: '/customer-with-contacts'
      })
   }, [])



   useEffect(() => {
      if (step3DrawerOpen) {
         const fetch = async () => {
            const { data } = await axios.get('/get-currency')




            setCurrencyData(data)
            setCurrencyModal(true)

            //group currency from offer.products

            let groupedCurrency = offer.products.map((item: any) => {
               return {
                  currency: item.currency
               }
            })

            //remove duplicate currency

            let uniqueCurrency = _.uniqBy(groupedCurrency, 'currency')
            console.log(groupedCurrency);
            setGroupedCurrency(uniqueCurrency)


         }
         fetch()
      }
      else {
         console.log('step3DrawerOpen', step3DrawerOpen)
      }
   }, [step3DrawerOpen])

   const user = JSON.parse(localStorage.getItem('user') || '{}')

   useEffect(() => {
      if (user?.role === 'admin') {
         //@ts-ignore
         usersQuery(CUSTOMER_MANAGEMENT_LIST, {
            endPoint: '/users-all'
         })
      }
   }, [])

   useEffect(() => {
      if (contactsData) {
         if (contact_id > 0) {
            form.setFieldsValue({
               contact_id: contact_id
            })
         }
      }
   }, [contactsData])

   useEffect(() => {
      if (getOfferNumberData) {
         dispatch(setOfferNumber(getOfferNumberData.offer_no))
         form.setFieldsValue({
            offer_no: getOfferNumberData.offer_no
         })
      }
   }, [getOfferNumberData])

   useEffect(() => {
      //@ts-ignore
      getOfferNumberQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: '/get-offer-no'
      })
   }, [])

   return (
      <div>

         <Modal open={contactModal} footer={null} onCancel={() => setContactModal(false)} title="Yeni Müşteri Ekle">
            <Form form={contactForm} layout="vertical" onFinish={handleSubmitContact}>
               <Form.Item
                  label="Firma Adı"
                  name="company_name"
                  rules={[{ required: true, message: 'Lütfen firma adı giriniz!' }]}
               >
                  <Input placeholder="Firma adı giriniz" />
               </Form.Item>
               <Form.Item
                  label="İlgili müşteri adı"
                  name="contact_name"
                  rules={[{ required: true, message: 'Lütfen ilgili müşteri adı giriniz!' }]}
               >
                  <Input placeholder="İlgili müşteri adı giriniz" />
               </Form.Item>
               <Form.Item
                  label="Telefon"
                  name="phone"
                  rules={[{ required: false, message: 'Lütfen telefon numarası giriniz!' }]}
               >
                  <Input placeholder="Telefon numarası giriniz" />
               </Form.Item>
               <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Lütfen email adresi giriniz!' }]}
               >
                  <Input placeholder="Email adresi giriniz" />
               </Form.Item>
               <Button isLoading={contactLoading} type="submit" color="primary">
                  Kaydet
               </Button>
            </Form>
         </Modal>
         <Drawer
            placement="bottom"
            height={windowHeight}
            onClose={() => {
               navigate(-1)
            }}
            open={step1DrawerOpen}
            title="Yeni teklif oluştur - Adım 1"
            extra={
               <Button type="button" onClick={() => form.submit()} color="primary">
                  İleri
               </Button>
            }
         >
            <div className="max-w-3xl container mx-auto">
               <Form
                  onFinish={handleStep1}
                  initialValues={{
                     offer_no: offer.offerNumber ?? getOfferNumberData?.offer_no ?? 1000,
                     contact_id: offer.contactId ?? contact_id,
                     term_time: offer.term_time ?? 30,
                  }}
                  layout="vertical"
                  form={form}
               >
                  <Card title="Genel Bilgiler" bordered={false}>
                     <div className="">
                        <Form.Item
                           label="Müşteri seçiniz"
                           name="contact_id"
                           rules={[{ required: true, message: 'Lütfen bir müşteri seçiniz!' }]}
                        >
                           <Select
                              onChange={(value: any) => {
                                 const find = contactsData?.data?.find((item: any) =>
                                    item.contacts.some((contact: any) => contact.id === value)
                                 )
                                 if (find) {
                                    const contactDetail = find.contacts.find((contact: any) => contact.id === value)
                                    dispatch(setContactDetail(contactDetail))
                                 }
                              }}
                              options={contactsData?.data
                                 .filter((item: any) => item.contacts.length > 0)
                                 .map((item: any) => {
                                    return {
                                       label: item.company_name,
                                       options: item.contacts.map((contact: any) => {
                                          return {
                                             label: contact.name,
                                             value: contact.id
                                          }
                                       })
                                    }
                                 })}
                              filterOption={(input: any, option: any) =>
                                 option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                 option?.options?.some(
                                    (item: any) => item.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                 )
                              }
                              showSearch
                              notFoundContent={
                                 <div className="flex flex-col items-center justify-center">
                                    <Empty description="Müşteri  bulunamadı" />
                                    <Button color="primary" onClick={() => setContactModal(true)}>
                                       Yeni Müşteri Ekle
                                    </Button>
                                 </div>
                              }
                              placeholder="müşteri seçiniz"
                              suffixIcon={<IconChevronDown />}
                           ></Select>
                        </Form.Item>
                        <Form.Item
                           label="Teklif No"
                           name="offer_no"
                           rules={[{ required: true, message: 'Lütfen bir teklif no giriniz!' }]}
                        >
                           <Input placeholder="Teklif no giriniz" />
                        </Form.Item>
                        {user?.role === 'admin' && (
                           <Form.Item
                              label="Atanacak Satışçı"
                              name="user_id"
                              rules={[{ required: true, message: 'Lütfen kullanıcı seçin!' }]}
                           >
                              <Select
                                 showSearch
                                 options={usersData?.result?.map((item: any) => {
                                    return {
                                       label: item.name,
                                       value: item.id
                                    }
                                 })}
                                 filterOption={(input: any, option: any) =>
                                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                 }
                                 placeholder="Satışçı seçiniz"
                                 suffixIcon={<IconChevronDown />}
                              ></Select>
                           </Form.Item>
                        )}
                        {/* <Alert color="danger">Lütfen vade sürenizi gün olarak giriniz örn: 60</Alert> */}
                        <Form.Item
                           label="Müşteri Ödeme Vadesi (Gün)"
                           name="term_time"
                           rules={[{ required: true, message: 'Vade Süresi!' }]}
                        >
                           <Input type="number" placeholder="Vade Süresi" />
                        </Form.Item>
                     </div>
                  </Card>
               </Form>
            </div>
         </Drawer>
         <Step2
            currentProducts={offer.products}
            drawerProps={{
               onClose: () => {
                  setStep2DrawerOpen(false)
                  navigate(-1)
               },
               open: step2DrawerOpen
            }}
            handleNext={handleStep2}
            handlePrev={() => {
               setStep1DrawerOpen(true)
               setStep2DrawerOpen(false)
            }}
         />
         <Drawer
            placement="bottom"
            height={step3DrawerHeight}
            onClose={() => {
               Modal.confirm({
                  title: 'Teklifi kaydetmeden çıkmak istediğinize emin misiniz',
                  content: 'Teklifi kaydetmeden çıkarsanız teklif oluşturulmayacaktır!',
                  okText: 'Evet',
                  cancelText: 'Hayır',
                  onOk: () => {
                     navigate(-1)
                     setStep3DrawerOpen(false)
                  }
               })
            }}
            open={step3DrawerOpen}
            title="Teklifi Önizle - Adım 3"
            extra={
               <div className="flex gap-2">
                  <Button
                     color="warning"
                     onClick={() => {
                        setStep2DrawerOpen(true)
                        setStep3DrawerOpen(false)
                     }}
                  >
                     Geri
                  </Button>
                  <Button
                     isLoading={offerCreateLoading}
                     color="primary"
                     onClick={() => {
                        saveOffer()
                     }}
                  >
                     Kaydet
                  </Button>


               </div>
            }
         >

            <Step3 />
         </Drawer>
      </div>
   )
}

export default Create
