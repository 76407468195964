import React from 'react'

// Ürün Tipi
interface Product {
   id: string
   sku: string
   name: string
   price: number
   stock: number
   currency: string
   imageUrl: string
   description: string // Ürün açıklaması
   variations?: Variation[]
}

// Varyasyon Tipi
interface Variation {
   id: string
   name: string
   options: VariationOption[]
}

// Varyasyon Seçenekleri
interface VariationOption {
   id: string
   value: string
   additionalPrice?: number
}

export const preparePromolifeVariations = (product: any): Variation[] => {
   const variations: Variation[] = []

   // UrunSecenek ve Secenekler var mı ve bir dizi mi kontrol ediliyor
   if (product.UrunSecenek && Array.isArray(product.UrunSecenek.Secenek)) {
      variations.push({
         id: product.UrunID, // Ürün ID'si varyasyon grubunun ID'si olarak kullanılıyor
         name: 'Renk ve Stok Bilgisi', // Varyasyon adı
         options: product.UrunSecenek.Secenek.map((opt: any) => ({
            id: opt.SecenekID, // Varyasyon ID'si
            value: `${opt.SecenekKod} - Merkez Stok: ${opt.SecenekMerkezStok}, Toplam Stok: ${opt.SecenekToplamStok}`, // Renk ve stok bilgileri
            additionalPrice: 0 // Promolife verisinde ek fiyat bilgisi yok
         }))
      })
   }

   return variations
}

// Varyasyonları hazırlayan fonksiyonlar
export const prepareDoyukVariations = (product: any): Variation[] => {
   const variations: Variation[] = []

   // Renkler varyasyonu
   if (product.Renkler) {
      variations.push({
         id: 'renkler',
         name: 'Renkler',
         options: product.Renkler.split('|').map((renk: string) => ({
            id: renk,
            value: renk,
            additionalPrice: 0 // Renkler için ek fiyat belirlemedik
         }))
      })
   }

   // Kapasite varyasyonu
   if (product.Kapasite.length > 0) {
      variations.push({
         id: 'kapasite',
         name: 'Kapasite',
         options: product.Kapasite.split('|').map((kapasite: string) => ({
            id: kapasite,
            value: kapasite,
            additionalPrice: 0 // Kapasite için de ek fiyat belirtilmemiş
         }))
      })
   }

   return variations
}

export const prepareIlpenVariations = (product: any): Variation[] => {
   const variations: Variation[] = []

   // UrunSecenek ve Secenekler var mı kontrol ediliyor
   if (Array.isArray(product.UrunSecenek.Secenek) && product.UrunSecenek.Secenek.length > 0) {
      variations.push({
         id: product.UrunGrupKodu, // Ürün grup kodunu varyasyon ID'si olarak kullanıyoruz
         name: 'Varyasyon', // Varyasyon adı
         options: product.UrunSecenek.Secenek.map((opt: any) => ({
            id: opt.VaryasyonID,
            value: `${opt.EkSecenekOzellik.Ozellik} - Stok: ${opt.StokAdedi}`, // Varyasyon adı ve stok adedi
            additionalPrice: 0 // İlpen verisinde ek fiyat bilgisi yok, bu yüzden 0
         }))
      })
   }

   return variations
}

export const prepareSaygiVariations = (product: any): Variation[] => {
   const variations: Variation[] = []

   // UrunSecenek ve Secenek var mı ve bir dizi mi kontrol ediliyor
   if (product.UrunSecenek && Array.isArray(product.UrunSecenek.Secenek)) {
      variations.push({
         id: product.UrunID, // Ürün ID'si varyasyon grubunun ID'si olarak kullanılıyor
         name: 'Renk ve Stok Bilgisi', // Varyasyon adı
         options: product.UrunSecenek.Secenek.map((opt: any) => ({
            id: opt.SecenekID, // Varyasyon ID'si
            value: `${opt.SecenekKod} - Toplam Stok: ${opt.SecenekToplamStok}`, // Renk ve stok bilgileri
            additionalPrice: 0 // Ek fiyat bilgisi yok
         }))
      })
   }

   return variations
}

export const preparePozitronVariations = (product: any): Variation[] => {
   const variations: Variation[] = []

   // 'stocks' objesi mevcut mu ve 'stock' elemanı bir dizi mi kontrol ediliyor
   if (product.stocks && Array.isArray(product.stocks.stock)) {
      // Renk varyasyonu için bir varyasyon grubu oluşturuluyor
      const colorVariation: Variation = {
         id: 'colors',
         name: 'Renkler',
         options: product.stocks.stock.map((stock: any) => ({
            id: stock.unique_id, // Her renk için benzersiz bir ID
            //value: stock.renk + ' - Stok Toplamı ' + stock.stock_count, // Renk ve stok bilgisi
            value: `${stock.renk} - Stok Toplamı ${stock.stock_count}`, // Renk ve stok bilgisi
            additionalPrice: 0 // Pozitron verisinde ek fiyat bilgisi yok
         }))
      }
      variations.push(colorVariation)
   }

   return variations
}

export const prepareAkdenizVariations = (product: any): Variation[] => {
   // Example: Assuming each product could potentially have multiple images as variations
   const variations: Variation[] = []

   // Collect all image URLs into an array (excluding empty or undefined)
   const imageUrls = []
   for (let i = 1; i <= 13; i++) {
      const imageUrl = product[`urunresim${i}`]
      if (imageUrl && typeof imageUrl === 'string') {
         imageUrls.push(imageUrl)
      }
   }

   // If there are any valid images, add them as variations
   if (imageUrls.length > 0) {
   }

   return variations
}

// React Component - Ürün Detayları ve Varyasyonları
const ProductDetail: React.FC<{ product: any; supplier: string; description: any; sku: any; image: any }> = ({
   product,
   supplier,
   description,
   sku,
   image
}) => {
   // Tek bir fonksiyonla tedarikçiye göre ürün detaylarını hazırlar
   const prepareProductDetail = (): Product => {
      switch (supplier) {
         case 'Akdeniz':
            return {
               id: product.urun_id,
               sku: product.urunkodu,
               name: product.urunadi,
               price: 0, // Assuming price is not provided in the example JSON
               stock: parseInt(product.stoktag.split(' ')[0], 10), // Extract the stock number from "stoktag"
               currency: 'TRY', // Assuming currency is TRY
               imageUrl: product.urunresim || product.stokresim, // Use the main product image or stock image
               description: product.urunaciklamasi.replace('<br>', '\n'), // Convert HTML break to newline for text
               variations: prepareAkdenizVariations(product)
            }
         case 'Doyuk':
            return {
               id: product.ID,
               sku: sku,

               name: product.Title,
               price: parseFloat(product.Price),
               stock: parseInt(product.ToplamStok),
               currency: 'TRY',
               imageUrl: image || '',
               description: description || 'Doyuk ürünü.',
               variations: prepareDoyukVariations(product)
            }

         case 'İlpen':
            return {
               id: product.UrunKartiID,
               sku: sku,
               name: product.UrunAdi,
               price: parseFloat(product.SatisFiyati),
               stock: parseInt(product.TumVaryantToplamStokAdedi),
               currency: 'TRY',
               imageUrl: image || '',
               description: description || 'Ilpen ürünü.',
               variations: prepareIlpenVariations(product)
            }
         case 'Promolife':
            const promolife_name = typeof product.UrunAd === 'string' ? product.UrunAd : 'Promolife Ürünü'
            const promolife_description =
               typeof product.UrunAciklama === 'string' ? product.UrunAciklama : 'Promolife ürünü açıklaması'

            return {
               id: product.UrunID,
               sku: sku,
               name: '', // Eğer ürün adı obje ise varsayılan bir değer
               price: parseFloat(product.UrunFiyat) || 0,
               stock: parseInt(product.UrunToplamStok),
               currency: product.UrunFiyatBirim || 'TRY',
               imageUrl: image, // Ürün resmi
               description: description || 'Promolife ürün açıklaması.', // Ürün açıklaması
               variations: preparePromolifeVariations(product) // Varyasyonları işleyen fonksiyon
            }

         case 'Saygı':
            return {
               id: product.UrunKod,
               sku: sku,
               name: product.UrunAd,
               price: parseFloat(product.UrunFiyat),
               stock: parseInt(product.UrunToplamStok),
               currency: product.UrunFiyatBirim || 'TRY',
               imageUrl: image || '',
               description: description || 'Saygi ürünü.',
               variations: prepareSaygiVariations(product)
            }

         case 'Pozitron':
            const name_pozitron = typeof product.name === 'string' ? product.name : 'Pozitron Ürünü'
            const image_pozitron = product.image
               ? product.image
               : product.images && product.images.url
               ? product.images.url[0]
               : '' // Varsayılan bir image URL alıyoruz
            const description_pozitron =
               typeof product.content === 'string' ? product.content : 'Pozitron ürünü açıklaması'

            return {
               id: product.id,
               sku: sku,
               name: name_pozitron, // Eğer name obje ise, varsayılan bir değer veriyoruz
               price: parseFloat(product.price),
               stock: parseInt(product.basic_stock_count),
               currency: 'USD',
               imageUrl: image || '', // Eğer image objesi hatalıysa default değer
               description: description || 'Pozitron ürünü.', // Eğer description yoksa varsayılan değer
               variations: preparePozitronVariations(product)
            }

         default:
            return {
               sku: sku,
               id: 'N/A',
               name: 'Bilinmeyen Ürün',
               price: 0,
               stock: 0,
               currency: 'TRY',
               imageUrl: image || '',
               description: 'Bilinmeyen tedarikçi.',
               variations: []
            }
      }
   }

   const productDetail = prepareProductDetail()

   return (
      <div className="max-w-lg mx-auto bg-white shadow-md rounded-lg overflow-hidden">
         <img className="w-full h-64 object-cover" src={productDetail.imageUrl} alt={productDetail.name} />
         <div className="p-4">
            <h2 className="text-2xl font-bold text-gray-800">{productDetail.name}</h2>
            <p className="text-gray-600 mt-2">
               Fiyat: {productDetail.price} {productDetail.currency}
            </p>
            <p className="text-gray-600 mt-2">Stok: {productDetail.stock}</p>
            <p className="text-gray-600 mt-2">Stok Kodu: {productDetail.sku}</p>
            <p className="text-gray-600 mt-2">
               Açıklama: <div dangerouslySetInnerHTML={{ __html: productDetail.description }}></div>
            </p>
            {productDetail.variations && productDetail.variations.length > 0 && (
               <div className="mt-4">
                  {productDetail.variations.map(variation => (
                     <div key={variation.id} className="mb-4">
                        <h3 className="text-xl font-semibold text-gray-700">{variation.name}</h3>
                        <ul className="mt-2 space-y-1">
                           {variation.options.map(option => (
                              <li key={option.id} className="text-gray-600">
                                 {option.value} {option.additionalPrice ? `(+${option.additionalPrice})` : ''}
                              </li>
                           ))}
                        </ul>
                     </div>
                  ))}
               </div>
            )}
         </div>
      </div>
   )
}

export default ProductDetail
