export const routes = {
   index: '/dashboard/index',
   myMail: '/dashboard/my-mail-list',
   mailbox: '/dashboard/mailbox',
   reports: '/dashboard/reports',
   products: '/dashboard/products',
   myRequests: '/dashboard/my-requests',
   offerManagement: {
      list: '/dashboard/offer-management',
      view: (id: string) => `/dashboard/offer-management/${id}/view`,
      add: '/dashboard/offer-management/new'
   },
   requests: {
      list: '/dashboard/requests',
      view: (id: string) => `/dashboard/requests/${id}/view`,
      add: '/dashboard/requests/new',
      mail: '/dashboard/requests/mail'
   },
   orders: '/dashboard/orders',
   customerManagement: {
      offers: (id: string) => `/dashboard/customer-management/${id}/offers`,
      list: '/dashboard/customer-management',
      view: (id: string) => `/dashboard/customer-management/${id}/view`,
      add: '/dashboard/customer-management/new',
      addExcel: '/dashboard/customer-management/new/excel'
   },
   supplierManagement: {
      list: '/dashboard/supplier-management',
      view: (id: string) => `/dashboard/supplier-management/${id}/view`,
      add: '/dashboard/supplier-management/new'
   },

   language: {
      edit: (id: string) => `/dashboard/languages/${id}/edit`,
      list: '/dashboard/language/index',
      create: '/dashboard/langauge/create'
   },

   transcationBuy: '/dashboard/transaction/buy',
   transcationSell: '/dashboard/transaction/sell',
   activities: '/dashboard/activities',
   home: '/admin',

   users: {
      list: '/dashboard/users',
      view: (id: string) => `/dashboard/users/${id}/view`,
      add: '/dashboard/users/new'
   },
   referrals: {
      list: '/dashboard/referrals',
      view: (id: string) => `/dashboard/referrals/${id}/view`,
      add: '/dashboard/referrals/new'
   },
   ipos: {
      list: '/dashboard/ipos',
      view: (id: string) => `/dashboard/ipos/${id}/view`,
      add: '/dashboard/ipos/new'
   },
   invested: {
      list: '/dashboard/invested',
      view: (id: string) => `/dashboard/invested/${id}/view`,
      add: '/dashboard/invested/new'
   },
   plans: {
      list: '/dashboard/plans',
      view: (id: string) => `/dashboard/plans/${id}/view`,
      add: '/dashboard/plans/new'
   },
   myReferrals: '/dashboard/my-referrals',
   myIpos: '/dashboard/my-ipos',
   myInvested: '/dashboard/my-invested',

   dashboard: '/dashboard',
   myStocks: '/dashboard/my-stocks',
   liveTrading: '/dashboard/live-trading',
   auth: {
      login: '/auth/login',
      forgotPassword: '/auth/forgot-password',
      resetPassword: '/auth/reset-password'
   },
   stocks: {
      list: '/dashboard/stocks',
      view: (id: string) => `/dashboard/stocks/${id}/view`
   },
   appSettings: '/dashboard/app-settings'
}
