import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { set } from 'lodash'

export interface OfferCreateState {
   offerNumber: string
   contactId: string
   contactDetail: any
   products: any
   sendMail: boolean
   success: boolean
   customContent: string
   userId: string
   term_time: string
   deliveryAddress?: string
   cc?: string
   note?: string
   otherSuppliers?: any
   isMail: boolean
   mailId: string
   selfCreated?: boolean
   currency?: string
}

const initialState: OfferCreateState = {
   offerNumber: '',
   contactId: '',
   contactDetail: {},
   products: [],
   sendMail: false,
   success: false,
   customContent: '',
   userId: '',
   term_time: '',
   deliveryAddress: '',
   cc: '',
   note: '',
   otherSuppliers: [],
   isMail: false,
   mailId: '',
   selfCreated: false,
   currency: 'TRY'
}

export const offerSlice = createSlice({
   name: 'offer',
   initialState,
   reducers: {
      setContactDetail: (state, action: PayloadAction<any>) => {
         state.contactDetail = action.payload
      },
      setOfferNumber: (state, action: PayloadAction<string>) => {
         state.offerNumber = action.payload
      },
      setContactId: (state, action: PayloadAction<string>) => {
         state.contactId = action.payload
      },
      addProduct: (state, action: PayloadAction<any>) => {
         state.products.push(action.payload)
      },
      removeProduct: (state, action: PayloadAction<number>) => {
         state.products.splice(action.payload, 1)
      },
      setProduct: (state, action: PayloadAction<any>) => {
         state.products = action.payload
      },
      removeAllProducts: state => {
         state.products = []
      },
      setSendMail: (state, action: PayloadAction<boolean>) => {
         state.sendMail = action.payload
      },
      setSuccess: (state, action: PayloadAction<boolean>) => {
         state.success = action.payload
      },
      setCustomContent: (state, action: PayloadAction<string>) => {
         state.customContent = action.payload
      },
      setUserId: (state, action: PayloadAction<string>) => {
         state.userId = action.payload
      },
      setTermTime: (state, action: PayloadAction<string>) => {
         state.term_time = action.payload
      },
      deliveryAddress: (state, action: PayloadAction<string>) => {
         state.deliveryAddress = action.payload
      },
      setCC: (state, action: PayloadAction<string>) => {
         state.cc = action.payload
      },
      addNote: (state, action: PayloadAction<string>) => {
         state.note = action.payload
      },
      setOtherSuppliers: (state, action: PayloadAction<any>) => {
         state.otherSuppliers = action.payload
      },
      setMail: (state, action: PayloadAction<boolean>) => {
         state.isMail = action.payload
      },
      setMailId: (state, action: PayloadAction<string>) => {
         state.mailId = action.payload
      },
      setSelfCreated: (state, action: PayloadAction<boolean>) => {
         state.selfCreated = action.payload
      },
      setCurrency: (state, action: PayloadAction<any>) => {
         state.currency = action.payload
      },
      clearOffer: state => {
         state.offerNumber = ''
         state.contactId = ''
         state.contactDetail = {}
         state.products = []
         state.sendMail = false
         state.success = false
         state.customContent = ''
         state.userId = ''
         state.term_time = ''
         state.deliveryAddress = ''
         state.cc = ''
         state.note = ''
         state.otherSuppliers = []
         state.isMail = false
         state.mailId = ''
         state.selfCreated = false
      },

   }
})

export const {
   setOfferNumber,
   setContactId,
   addProduct,
   removeProduct,
   setProduct,
   removeAllProducts,
   setContactDetail,
   setSendMail,
   setSuccess,
   setCustomContent,
   setUserId,
   setTermTime,
   deliveryAddress,
   setCC,
   addNote,
   setOtherSuppliers,
   setMail,
   setMailId,
   setSelfCreated,
   setCurrency,
   clearOffer
} = offerSlice.actions

// Action creators are generated for each case reducer function
// export const { setOfferCreate, setShouldUpdateProfile, setToken } = offerSlice.actions

export default offerSlice.reducer
