import React, { useEffect } from 'react'
import SummaryWidget from './summary-widget'
import TotalAppointment from './total-appointment'
import RecentActivities from './Times'
import useFetch from 'hooks/use-fetch'
import { OFFER_MANAGEMENT_LIST, OFFER_MANAGEMENT_LIST_URL, OFFER_MANAGEMENT_SHOW } from 'api/offer-management'
import { Input, Popover, Title } from 'rizzui'
import { Form, Select, TableColumnProps } from 'antd'
import moment from 'moment'
import TableComponents from 'components/Table'
import { Button, Card, CardBody, CardHeader, Divider, Tooltip } from '@nextui-org/react'
import Lottie from 'react-lottie'
import { getOrderStatusWithClass, getOrderWithAnimation, orderStatusText } from 'utils/get-order-status'
import cn from 'utils/class-names'

import type { DatePickerProps } from 'antd'
import { DatePicker, Space } from 'antd'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { USER_SHOW } from 'api/user'
import { MoneyFormatTR } from 'hooks/currency'

dayjs.extend(customParseFormat)

const { RangePicker } = DatePicker

const dateFormat = 'YYYY/MM/DD'
const weekFormat = 'MM/DD'
const monthFormat = 'YYYY/MM'

/** Manually entering any of the following formats will perform date parsing */
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY']

const customFormat: DatePickerProps['format'] = value => `custom format: ${value.format(dateFormat)}`

const customWeekStartEndFormat: DatePickerProps['format'] = value =>
   `${dayjs(value).startOf('week').format(weekFormat)} ~ ${dayjs(value).endOf('week').format(weekFormat)}`

const Reports = () => {
   const [fetchDashboard, { data: dashboardData, loading }] = useFetch<any>()
   const [offerManagementQuery, { data, loading: offerManagementLoading }] = useFetch<any>()
   const [reportsQuery, { data: reportsData, loading: reportsLoading }] = useFetch<any>()
   const [usersQuery, { data: usersData, loading: usersLoading }] = useFetch<any>()
   const [form] = Form.useForm()
   const [startDate, setStartDate] = React.useState<any>(null)
   const [endDate, setEndDate] = React.useState<any>(null)

   React.useEffect(() => {
      //@ts-ignore
   }, [])

   useEffect(() => {
      //@ts-ignore
      usersQuery(USER_SHOW, {
         endPoint: '/users-all'
      })
   }, [])

   const onFinish = (values: any) => {
      const user_ids = values.user_ids?.join(',')
      const offer_type = values.offer_type?.join(',')
      const search = values.search == 'undefined' ? '' : values.search
      const from_date = startDate
      const to_date = endDate

      console.log({
         user_ids,
         offer_type,
         search,
         from_date,
         to_date
      })

      //@ts-ignore

      reportsQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: `reports/orders?user_ids=${user_ids}&offer_type=${offer_type}&search=${search}&from_date=${from_date}&to_date=${to_date}`
      })
   }

   const ordersColumns: TableColumnProps<any>[] = [
      {
         title: 'Teklif Grup No',
         dataIndex: 'no',
         key: 'no',
         render: (no: any, record) => {
            return <div className="flex items-center gap-3">{record.offer_no}</div>
         }
      },
      {
         title: 'Müşteri seçiniz (Firma)',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {/* {record.contact.name} ({record.contact.customer.company_name}) */}
                  {record.contact_name}
               </div>
            )
         }
      },
      {
         title: 'Durumu',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const color = record.is_ordered === 1 ? 'text-green-500' : 'text-red-500'
            const background = record.is_ordered === 1 ? 'bg-green-500' : 'bg-red-100'
            return (
               <div className={cn(getOrderStatusWithClass(record.offer_status), 'block')}>
                  {orderStatusText[record.offer_status]}
               </div>
            )
         }
      },
      {
         title: 'Sipariş Tarihi',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            const color = record.is_ordered === 1 ? 'text-green-500' : 'text-red-500'
            const background = record.is_ordered === 1 ? 'bg-green-500' : 'bg-red-100'
            return <div className="flex-col items-center gap-3">{moment(record.created_at).format('LL')}</div>
         }
      },

      {
         title: 'Ürünler',
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            console.log(record)
            return (
               <div className="flex items-center gap-3">
                  {/* {moment(record.updated_at).format('LL')} */}
                  <Tooltip
                     content={
                        <table className="p-10 w-[500px]">
                           <thead>
                              <tr>
                                 <th className="text-left">Ürün</th>
                                 <th className="text-left">Maliyet</th>
                                 <th className="text-left">Kâr</th>
                              </tr>
                           </thead>
                           <tbody>
                              {record.products.map((product: any) => (
                                 <tr>
                                    <td>{product.product_name.substr(0, 20)}...</td>
                                    <td>{MoneyFormatTR(product.price)}</td>
                                    <td>{MoneyFormatTR(product.profit)}</td>
                                 </tr>
                              ))}
                           </tbody>
                        </table>
                     }
                  >
                     <Button variant="bordered">Ürünler & Kâr oranları</Button>
                  </Tooltip>
               </div>
            )
         }
      }
   ]

   const user = JSON.parse(localStorage.getItem('user') || '{}')
   return (
      <div className="grid  gap-6 @container @[59rem]:gap-7">
         <div className="mt-5">
            <Card></Card>
            <Card className="mt-5">
               <CardHeader className="flex flex-col items-start mb-5 ">
                  <div>
                     <Title as="h2">Filtrele</Title>
                  </div>

                  <Form
                     className="grid grid-cols-6 gap-5 items-center  w-full"
                     layout="vertical"
                     form={form}
                     onFinish={onFinish}
                  >
                     <Form.Item label="Kelime ile ara" name="search">
                        <Input placeholder="Arama Yapın" />
                     </Form.Item>
                     <Form.Item label="Satışçılar" name="user_ids">
                        <Select
                           placeholder="Satışçılar"
                           mode="multiple"
                           showSearch
                           optionFilterProp="children"
                           filterOption={(input, option: any) =>
                              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           }
                        >
                           {usersData?.result?.map((user: any) => (
                              <Select.Option key={user?.id} value={user?.id}>
                                 {user?.name}
                              </Select.Option>
                           ))}
                        </Select>
                     </Form.Item>

                     <Form.Item label="Teklif Tipi" name="offer_type">
                        <Select placeholder="Teklif Tipi" mode="multiple">
                           <Select.Option value="manuel">Sistemden oluşturulan</Select.Option>
                           <Select.Option value="web">Web sitesinden gelen</Select.Option>
                        </Select>
                     </Form.Item>
                     <Form.Item label="Teslim Tarihleri" name="date_range">
                        <RangePicker
                           format={dateFormat}
                           onChange={(dates, dateStrings) => {
                              setStartDate(dateStrings[0])
                              setEndDate(dateStrings[1])
                           }}
                        />
                     </Form.Item>

                     <Button isLoading={reportsLoading} type="submit" color="primary" className="">
                        Filtrele
                     </Button>
                  </Form>
               </CardHeader>
               <Divider />
               <CardBody>
                  <TableComponents
                     columns={ordersColumns}
                     data={reportsData?.data || []}
                     pagination={false}
                     loading={reportsLoading}
                  />
               </CardBody>
            </Card>
         </div>
      </div>
   )
}

export default Reports
