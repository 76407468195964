import { Button, Card, CardHeader, CardBody, Popover, PopoverTrigger, PopoverContent } from '@nextui-org/react'
import { IconCalendar, IconMail, IconMapPin, IconPdf } from '@tabler/icons-react'
import { Drawer, TableColumnProps, Tag, message } from 'antd'
import TableComponents from 'components/Table'
import { createUid } from 'hooks/use-id'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { Input, Switch, Textarea } from 'rizzui'
import { addNote, setCC, setCustomContent, setSendMail } from 'features/user/offer'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from '@nextui-org/react'
import mail from 'utils/mail'
import useFetch from 'hooks/use-fetch'
import { OFFER_MANAGEMENT_STORE } from 'api/offer-management'

function Step3() {
   const { isOpen, onOpen, onOpenChange } = useDisclosure()
   const [mailContent, setMailContent] = React.useState('')
   const [termTime, setTermTime] = React.useState('')
   const [deliveryAddress, setDeliveryAddress] = React.useState('')
   const offer = useSelector((state: RootState) => state.offer)
   const [isOpenModal, setIsOpenModal] = React.useState(false)
   const dispatch = useDispatch()
   const [ccModal, setCcModal] = React.useState(false)
   const [noteModal, setNoteModal] = React.useState(false)
   const [cc, setCc] = React.useState('')
   const [note, setNode] = useState('')
   const [previewOffer, { loading, data, error }] = useFetch<any>()
   const [previewModal, setPreviewModal] = useState(false)
   const colums: TableColumnProps<any>[] = [
      {
         key: 'product',
         title: 'Ürün Adı (Birim Fiyat)',
         dataIndex: 'name',
         render: (text, record) => {
            return (
               <div className="flex gap-2">
                  <span>{text}</span>
                  <span>{record.price}</span>
               </div>
            )
         }
      },
      {
         key: 'quantity',
         title: 'Adet / Miktar',
         dataIndex: 'stock'
      },
      {
         key: 'delivery_date',
         title: 'Teslim Tarihi',
         dataIndex: 'delivery_date'
      },
      {
         key: 'commission',
         title: 'Kâr Oranı  (Kâr Tipi)',
         dataIndex: 'commission',
         render: (text, record) => {
            return (
               <div className="flex gap-2">
                  <span>{text}</span>
                  <span>{record.commission_type == 1 ? 'Yüzde' : 'Sabit'}</span>
               </div>
            )
         }
      }
   ]

   const addCc = () => {
      dispatch(setCustomContent(mail(mailContent)))
      setIsOpenModal(false)
   }

   useEffect(() => {
      if (data) {
         setPreviewModal(true)
      }
   }, [data])

   return (
      <div className="space-y-2">
         <Drawer
            placement="bottom"
            open={previewModal}
            height={window.innerHeight}
            onClose={() => {
               setPreviewModal(false)
            }}
         >
            <div dangerouslySetInnerHTML={{ __html: data?.data ?? '' }} style={{ width: '100%', height: '100%' }}></div>
         </Drawer>
         <Modal
            size="5xl"
            isOpen={isOpenModal}
            onClose={() => {
               setIsOpenModal(false)
            }}
            onOpenChange={onOpenChange}
            isDismissable={false}
            classNames={{
               wrapper: 'z-[9999]'
            }}
         >
            <ModalContent>
               {onClose => (
                  <>
                     <ModalHeader className="flex flex-col gap-1">Mail İçeriği</ModalHeader>
                     <div className="grid grid-cols-2">
                        <div style={{ height: 500 }} dangerouslySetInnerHTML={{ __html: mail(mailContent) }}></div>
                        <ReactQuill
                           style={{ height: 700 }}
                           value={mailContent}
                           onChange={(value: any) => setMailContent(value)}
                        />
                     </div>
                     <ModalFooter style={{ marginTop: 30 }}>
                        <Button color="danger" variant="light" onPress={onClose}>
                           Kapat
                        </Button>
                        <Button
                           color="primary"
                           onPress={() => {
                              dispatch(setCustomContent(mail(mailContent)))
                              setIsOpenModal(false)
                           }}
                        >
                           Kaydet
                        </Button>
                     </ModalFooter>
                  </>
               )}
            </ModalContent>
         </Modal>

         <Card>
            <CardBody>
               <div className="flex gap-2  justify-between">
                  <div className="flex flex-col space-y-2">
                     <span className="flex gap-2">
                        <span className="font-bold text-md">Teklif No:</span>
                        <span>{offer.offerNumber}</span>
                     </span>
                     <span className="flex gap-2">
                        <span className="font-bold text-md">Cc:</span>
                        <span>{offer.cc}</span>
                     </span>
                     <span className="flex gap-2">
                        <IconMapPin size={20} />
                        <span>{offer.contactDetail?.name}</span>
                     </span>
                     {/* <span className='flex gap-2'>
                                <span className='font-bold text-md'>
                                    UUID
                                </span>
                                <span>
                                    {createUid()}
                                </span>
                            </span> */}
                     <span className="flex gap-2">
                        <span className="font-bold text-md flex gap-2">
                           <IconCalendar size={20} />
                           <span>Tarih</span>
                        </span>
                        <span>{new Date().toLocaleDateString()}</span>
                     </span>
                     <div className="flex flex-col gap-2">
                        <Switch
                           onChange={() => {
                              dispatch(setSendMail(!offer.sendMail))
                           }}
                           label="Teklifi mail olarak gönder"
                           size="xl"
                        />
                        <Button
                           style={{
                              display: offer.sendMail ? 'block' : 'none'
                           }}
                           color="primary"
                           variant="solid"
                           onPress={() => {
                              setIsOpenModal(true)
                           }}
                        >
                           Mail İçeriğini Düzenle
                        </Button>
                     </div>
                     <Popover
                        size="lg"
                        placement="bottom"
                        showArrow
                        offset={10}
                        isOpen={ccModal}
                        onOpenChange={open => setCcModal(open)}
                     >
                        <PopoverTrigger>
                           <Button color="primary">Cc Ekle</Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-[240px]">
                           {titleProps => (
                              <div className="px-1 py-2 w-full">
                                 <p className="text-small font-bold text-foreground" {...titleProps}>
                                    Mail Cc
                                 </p>
                                 <div className="mt-2 flex flex-col gap-2 w-full">
                                    <Input
                                       value={cc}
                                       onChange={e => {
                                          setCc(e.target.value)
                                       }}
                                       placeholder="Cc"
                                    />
                                    <Button
                                       color="primary"
                                       className="mt-2"
                                       onClick={() => {
                                          dispatch(setCC(cc))
                                          setCcModal(false)
                                          message.success('Cc Eklendi')
                                       }}
                                    >
                                       Kaydet
                                    </Button>
                                 </div>
                              </div>
                           )}
                        </PopoverContent>
                     </Popover>
                     <Popover
                        size="lg"
                        placement="bottom"
                        showArrow
                        offset={10}
                        isOpen={noteModal}
                        onOpenChange={open => setNoteModal(open)}
                     >
                        <PopoverTrigger>
                           <Button color="primary">Not Ekle</Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-[400px]">
                           {titleProps => (
                              <div className="px-1 py-2 w-full">
                                 <p className="text-small font-bold text-foreground" {...titleProps}>
                                    Notunuz
                                 </p>
                                 <div className="mt-2 flex flex-col gap-2 w-full">
                                    <Textarea
                                       value={note}
                                       onChange={e => {
                                          setNode(e.target.value)
                                       }}
                                       placeholder="Notunuz"
                                    />
                                    <Button
                                       color="primary"
                                       className="mt-2"
                                       onClick={() => {
                                          dispatch(addNote(note))
                                          setNoteModal(false)
                                          message.success('Notunuz Eklendi')
                                       }}
                                    >
                                       Kaydet
                                    </Button>
                                 </div>
                              </div>
                           )}
                        </PopoverContent>
                     </Popover>
                  </div>
                  <div className="flex gap-2">
                     <Button
                        onClick={() => {
                           //@ts-ignore
                           previewOffer(OFFER_MANAGEMENT_STORE, {
                              endPoint: 'preview-offer',
                              body: {
                                 offer_number: offer.offerNumber,
                                 products: offer.products,
                                 currency: offer.currency,
                              }
                           })
                        }}
                        startContent={<IconPdf size={20} />}
                        color="primary"
                        variant="solid"
                     >
                        Teklifi Önizle
                     </Button>
                  </div>
               </div>
            </CardBody>
         </Card>
         <Card title="Ürünler">
            <CardBody>
               <TableComponents columns={colums} data={offer.products} pagination={false} loading={false} />
            </CardBody>
         </Card>
      </div>
   )
}

export default Step3
