import React from 'react'
import { Form, Input, InputNumber, Select, Button, Upload, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { addProduct as addProductoffer } from 'features/user/offer'
const { TextArea } = Input

const ProductForm = ({ isFromOffer, handleClose, setOfferProduct, currentProducts, setCreateProductDrawer }: any) => {
   const [form] = Form.useForm()

   const offerProduct = useSelector((state: any) => state.offer.products)
   const onFinish = async (values: any) => {
      const formData = new FormData()

      // Form verilerini FormData'ya ekle
      Object.keys(values).forEach(key => {
         if (key === 'image' && values[key] && values[key].length > 0) {
            // 'image' için file objesini formData'ya ekle
            formData.append(key, values[key][0].originFileObj)
         } else {
            formData.append(key, values[key])
         }
      })

      try {
         const response = await axios.post('/products', formData, {
            headers: {
               'Content-Type': 'multipart/form-data'
            }
         })

         const data = response.data.data

         if (response.status === 200) {
            if (isFromOffer) {
               // Eğer ürün teklif sayfasından ekleniyorsa
               // Ürünü teklif sayfasına ekle
               let productArray = {
                  id: data.urun_id,
                  name: data.urun_adi,
                  model: data.sku,
                  price: data.satis_fiyati,
                  stock: data.stok_adedi,
                  product_qty: data.stok_adedi,
                  delivery_date: '',
                  commission: 0,
                  commission_type: '1',
                  description: data.aciklama,
                  services: [],
                  isReserve: false,
                  reserveEndDate: '',
                  supplier: data.tedarikci,
                  image: data.urun_resim_url,
                  currency: data.para_birimi
               }

               console.log('productArray:', productArray)

               setOfferProduct([...currentProducts, productArray])

               message.success('Ürün teklife eklendi.')
               handleClose(false)
               setCreateProductDrawer(false)
            }
            message.success('Ürün başarıyla eklendi!')
         }
      } catch (error) {
         message.error('Ürün eklenirken bir hata oluştu.')
         console.error('Error:', error)
      }
   }
   //create soft input for ux
   const inputClassName =
      'border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring focus:border-blue-300'

   return (
      <Form
         form={form}
         layout="vertical"
         onFinish={onFinish}
         initialValues={{
            sort_order: 0,
            status: '1'
         }}
      >
         <Form.Item
            name="image"
            label="Ürün Görseli"
            valuePropName="fileList"
            getValueFromEvent={e => (Array.isArray(e) ? e : e && e.fileList)}
            rules={[{ required: true, message: 'Lütfen bir görsel yükleyin!' }]}
         >
            <Upload name="image" listType="picture" accept="image/*" beforeUpload={() => false}>
               <Button icon={<UploadOutlined />}>Görsel Yükle</Button>
            </Upload>
         </Form.Item>

         <Form.Item name="name" label="Ürün Adı" rules={[{ required: true, message: 'Lütfen ürün adını girin!' }]}>
            <Input className={inputClassName} placeholder="Ürün adı" />
         </Form.Item>

         <Form.Item
            name="sku"
            label="SKU (Stok Kodu)"
            rules={[{ required: true, message: 'Lütfen stok kodunu girin!' }]}
         >
            <Input className={inputClassName} placeholder="Stok Kodu" />
         </Form.Item>

         <Form.Item name="price" label="Fiyat" rules={[{ required: true, message: 'Lütfen fiyatı girin!' }]}>
            <Input className={inputClassName} placeholder="Fiyat" />
         </Form.Item>
         <Form.Item
            name="currency"
            label="Para Birimi"
            rules={[{ required: true, message: 'Lütfen para birimini girin!' }]}
         >
            <Select placeholder="Para Birimi Seçin" style={{ width: '100%' }} defaultValue="TRY">
               <Select.Option value="TRY">TRY</Select.Option>
               <Select.Option value="USD">USD</Select.Option>
               <Select.Option value="EUR">EUR</Select.Option>
               <Select.Option value="GBP">GBP</Select.Option>
            </Select>
         </Form.Item>

         <Form.Item name="stock" label="Stok Adedi" rules={[{ required: true, message: 'Lütfen stok adedini girin!' }]}>
            <InputNumber min={0} placeholder="Stok Adedi" style={{ width: '100%' }} />
         </Form.Item>

         <Form.Item name="description" label="Açıklama (Opsiyonel)" rules={[{ required: false }]}>
            <TextArea rows={4} placeholder="Açıklama" />
         </Form.Item>

         <Form.Item name="sort_order" label="Sırala" rules={[{ required: true, message: 'Lütfen sıralamayı girin!' }]}>
            <InputNumber min={0} placeholder="Sırala" style={{ width: '100%' }} />
         </Form.Item>

         <Form.Item name="status" label="Durumu" rules={[{ required: true, message: 'Lütfen durumu seçin!' }]}>
            <Select placeholder="Durum Seçin">
               <Select.Option value="1">Aktif</Select.Option>
               <Select.Option value="0">Pasif</Select.Option>
            </Select>
         </Form.Item>

         <Form.Item>
            <Button type="primary" htmlType="submit">
               Gönder
            </Button>
         </Form.Item>
      </Form>
   )
}

export default ProductForm
